import $$ from 'dom7';
import Framework7 from './framework7-custom.js';

// Import F7 Styles
import '../css/framework7-custom.less';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.less';

import '../css/general.css'

import FontAwsome from '../../node_modules/@fortawesome/fontawesome-free/css/all.css';


// Import Routes
import routes from './routes.js';

// Import main app component
import App from '../app.f7.html';
import ListIndexComponent from 'framework7/components/list-index/list-index';

var app = new Framework7({
  root: '#app', // App root element
  component: App, // App main component

  name: 'Law of Life', // App name
  theme: 'auto', // Automatic theme detection


  // App routes
  routes: routes,
  // Register service worker
  serviceWorker: {
    path: '/service-worker.js',
  },
});