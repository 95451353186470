
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  // Lifecycle Hooks
  beforeCreate() {
    console.log('componentBeforeCreate', this);
  },

  created() {
    console.log('componentCreated', this);
  },

  beforeMount() {
    console.log('componentBeforeMount', this);
  },

  mounted() {
    console.log('componentMounted', this);
  },

  beforeDestroy() {
    console.log('componentBeforeDestroy', this);
  },

  destroyed() {
    console.log('componentDestroyed', this);
  },

  // Component Data
  data: function () {
    // Must return an object
    return {
      articles: null,
      name: 'Jimmy',
      age: 25,
      like: ['Tennis', 'Chess', 'Football']
    };
  },
  // Component Methods
  methods: {
    refreshContent: function () {
      var self = this;
      setTimeout(function () {
        // location.reload();
        self.$app.views.main.yrouter.navigate(self.$app.views.main.router.url, {
          reloadCurrent: true
        }); // var $ptrContent = $$(page.el).find('.ptr-content');

        self.$app.toast.show({
          text: 'reloading',
          position: 'bottom',
          closeTimeout: 2000,
          cssClass: 'toast-round bg-color-yellow text-color-black'
        });
        self.$app.ptr.done();
      }, 1000);
    },
    openAlert: function () {
      var self = this;
      self.$app.dialog.alert('Hello World');
    },
    loadData: function () {
      var self = this;
      self.$app.request.json('static/datasets/articles.json', function (data) {
        if (data) {
          self.$setState({
            articles: data.filter(function (article) {
              return article.topic === self.$route.params.topic;
            })
          });
        }
      });
    }
  },
  // Page Events
  on: {
    pageMounted: function (e, page) {
      console.log('pageMounted', page);
    },
    pageInit: function (e, page) {
      var self = this;
      self.loadData();
      self.$('.ptr-content').on('ptr:refresh', self.refreshContent);
    },
    pageBeforeIn: function (e, page) {
      console.log('pageBeforeIn', page);
    },
    pageAfterIn: function (e, page) {
      console.log('pageAfterIn', page);
    },
    pageBeforeOut: function (e, page) {
      console.log('pageBeforeOut', page);
    },
    pageAfterOut: function (e, page) {
      console.log('pageAfterOut', page);
    },
    pageBeforeRemove: function (e, page) {
      console.log('pageBeforeRemove', page);
    }
  },
  id: '44efd658bd',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n  <div class="page">\n    <div class="navbar">\n      <div class="navbar-bg"></div>\n      <div class="navbar-inner sliding">\n        <div class="left">\n          <a href="#" class="link back">\n            <i class="icon icon-back"></i>\n            <span class="if-not-md" data-i18n="back">Back</span> \n          </a>\n        </div>\n        <div class="title">';
      r += Template7Helpers.js.call(ctx_1, 'return this.$route.params.topic[0].toUpperCase() + this.$route.params.topic.slice(1)', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>\n      </div>\n    </div>\n    <div class="page-content ptr-content" data-ptr-distance="55" data-ptr-mousewheel="true">\n      <div class="ptr-preloader">\n        <div class="preloader"></div>\n        <div class="ptr-arrow"></div>\n      </div>\n\n      ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.articles, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n      <div class="cards row no-gap">\n        ';
          r += Template7Helpers.each.call(ctx_2, ctx_2.articles, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n        <div class="col-100 medium-50 xlarge-33">\n          <a href="/article/';
              r += c(ctx_3.id, ctx_3);
              r += '/" class="card" style="background-image: url(\'';
              r += c(ctx_3.image, ctx_3);
              r += '\');">\n            <div class="card-content card-content-padding">\n              <p style=\'font-size: 1.6em\'>';
              r += c(ctx_3.title, ctx_3);
              r += '</p>\n              <div class="badge color-yellow text-color-black" style=\'padding: 0 .8em 0.2em .8em\'>';
              r += c(ctx_3.topic, ctx_3);
              r += '</div>\n            </div>\n          </a>\n        </div>\n        ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n      </div>\n      ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n    </div>\n  </div>\n';
      return r;
    }(this);
  },

  style: `
[data-f7-44efd658bd] p {
    margin: 10px 0;
  }
[data-f7-44efd658bd] .cards .card {
		background-color: #292929;
    background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		box-shadow: none;
		color: #FFFFFF;
    display: flex;
    /* height: 48vw; */
    height: 48vh;
	}

[data-f7-44efd658bd] .card .card-content {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

[data-f7-44efd658bd] .card-content p {
    text-shadow: 1px 1px 10px #000000;
  }
`,
  styleScoped: true
};
    