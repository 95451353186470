export default {
  id: 'e043b15bb5',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n<div class="page" data-name="about">\n  <div class="navbar">\n    <div class="navbar-bg"></div>\n    <div class="navbar-inner sliding">\n      <div class="left">\n        <a href="#" class="link back">\n          <i class="icon icon-back"></i>\n          <span class="if-not-md">Back</span>\n        </a>\n      </div>\n      <div class="title">About</div>\n    </div>\n  </div>\n  <div class="page-content">\n    <!-- <div class="block-title">About My App</div>\n    <div class="block block-strong">\n      <p>Fugiat perspiciatis excepturi, soluta quod non ullam deleniti. Nobis sint nemo consequuntur, fugiat. Eius perferendis animi autem incidunt vel quod tenetur nostrum, voluptate omnis quasi quidem illum consequuntur, a, quisquam.</p>\n      <p>Laudantium neque magnam vitae nemo quam commodi, in cum dolore obcaecati laborum, excepturi harum, optio qui, consequuntur? Obcaecati dolor sequi nesciunt culpa quia perspiciatis, reiciendis ex debitis, ut tenetur alias.</p>\n    </div> -->\n    <div class="block">\n      <p>"There is nothing, save the selfish heart of man, that lives unto itself. No bird that cleaves the air, no animal that moves upon the ground, but ministers to some other life. There is no leaf of the forest, or lowly blade of grass, but has its ministry. Every tree and shrub and leaf pours forth that element of life without which neither man nor animal could live; and man and animal, in turn, minister to the life of tree and shrub and leaf. The flowers breathe fragrance and unfold their beauty in blessing to the world. The sun sheds its light to gladden a thousand worlds. The ocean, itself the source of all our springs and fountains, receives the streams from every land, but takes to give. The mists ascending from its bosom fall in showers to water the earth, that it may bring forth and bud."</p>\n    </div>\n  </div>\n</div>\n';
      return r;
    }(this);
  },

  styleScoped: false
};